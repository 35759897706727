import { Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TrustSection from "../components/sections/trustSection"
import HeadingGroup from "../components/ui/headingGroup"
import Banner from "../components/ui/banner"
import GetStartedSection from "../components/sections/getStartedSection"
import RequestDemoDrawer from "../components/drawers/requestDemoDrawer";
import SignUpDrawer from "../components/drawers/signUpDrawer";
import PricingDrawer from "../components/drawers/pricingDrawer";
import PageBreak from "../components/ui/pageBreak";
import ImageGroup from "../components/ui/imageGroup";
import styled from "styled-components";
import PrimaryButton from "../components/ui/primaryButton";

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const EducationPage = () => {
  const [openPriceCalculator, setOpenPriceCalculator] = useState(false);
  const [openRequestDemo, setOpenRequestDemo] = useState(false);
  const [openGettingStarted, setOpenGettingStarted] = useState(false);

  const handlePriceCalculatorClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenPriceCalculator(!openPriceCalculator);
      event.preventDefault();
    }
  }

  const handleRequestDemoClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenRequestDemo(!openRequestDemo);
      event.preventDefault();
    }
  }

  const handleGettingStartedClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenGettingStarted(!openGettingStarted);
      event.preventDefault();
    }
  }

  return (
    <Layout>
      <SEO title="Education Biometric Solutions" />
      <Banner>
        <p>
          Are you looking for an immediate price estimate?&nbsp;<Link to="/pricing" onClick={handlePriceCalculatorClick}>Price Calculator</Link>
        </p>
      </Banner>
      <Hero
        handleRequestDemoClick={handleRequestDemoClick}
        handleGettingStartedClick={handleGettingStartedClick}
        statement="Does your school require"
        punchlines={["Time and Attendance?", "Multisite Control?", "Real Time Monitoring?", "Access Control?", "Portable Biometric Hardware?"]}
        caption="We provide cloud-based Access Control as well as Time and Attendance solutions which rely on fingerprint or facial recognition."
      ></Hero>
      <TrustSection />
      <HeadingGroup heading="Biometric Learner Attendance Register">
        <p>
          Beyond Attendance is perfectly suited to serve the needs of the education sector. With our user-friendly interface and highly affordable pricing models, we provide cloud-based Attendance Registers as well as Access Control applications. Common applications of our system include tracking learners when arriving and leaving school premises, tracking attendance for individual classes, and tracking attendance for extra mural activities.
        </p>
        <p>
          Beyond Attendance allows for integration with most education administration systems. Our mobile app is perfectly suited for real-time tracking of students leaving school premises whether it be for sport events, learning excursions and cultural activities.
        </p>
        <p>
          Our system can also be utilised to track and record staff working hours and export these hours into a payroll system via our various built in work rules and schedules.
        </p>
      </HeadingGroup>
      <PageBreak>Take a look at some of our key features below&nbsp;<span role="img" aria-label="fire">🔥</span> or <Link to="/contact-us">get in touch</Link></PageBreak>


      <ImageGroup reverse={true} image="../images/education-integration.svg" heading="Integration with Existing School Administration Software">
        <p>
          Beyond Attendance has multiple software API’s available which allows us to share data directly with school administrations platforms such as Ed-Admin, D6 and the government mandated SA-SAMS platform. This flexibility ensures we can cater to your unique requirements.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/pricing.svg" heading="Competitive Pricing">
        <p>
          Our product has a special scaled pricing model with discounts for the education sector which allows you to choose the Attendance Register package that best suits your needs and offers maximum value for money. The system is a month-to-month rental so you are not locked in by fixed term contracts and your monthly bill will depend on the number of active persons on the system.
        </p>
        <ButtonContainer>
          <PrimaryButton to="/pricing" onClick={handlePriceCalculatorClick} padding="0.8rem" margin="0.8rem 0.8rem 0.8rem 0" fontSize="1rem">Price Calculator</PrimaryButton>
        </ButtonContainer>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/education-biometric-options.svg" heading="Multiple Biometric Options">
        <p>
          You will receive full support for a wide range of biometric devices. Available options include fingerprint and facial recognition, blood vessel mapping and thermal temperature detection. Additional options include USB based units that can be linked directly into cell phones and tablets to allow for real time registers to be maintained while learners are not on school premises.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/education-time-and-attendance.svg" heading="Time and Attendance for Educators and Non-Educators">
        <p>
          Beyond Attendance allows you to track hours worked for educators and non-educators and export this data directly to your payroll. This performs operations in a traditional business fashion with all the controls afforded to ensure staff are working their contractual hours.
        </p>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/education-access.svg" heading="Access Control">
        <p>
          Movement within specific areas and rooms can be tightly controlled and restricted using our biometric system in conjunction with turnstiles and magnetic door locks. Facial and fingerprint recognition reliably and securely denies access to unauthorised personnel trying to gain entry. Access can also be limited to certain groups of people/learners to ensure they don’t enter unauthorised locations.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/education-portable.svg" heading="Portable Biometric Options">
        <p>
          Our portable biometric offerings are perfect for keeping track of learners when they leave school premises for activities such as sporting events and cultural excursions. Real time data can be fed back to administrative users to quickly ascertain where groups of students are with live geolocation features.
        </p>
      </ImageGroup>

      <GetStartedSection
        handleRequestDemoClick={handleRequestDemoClick}
        handleGettingStartedClick={handleGettingStartedClick} />

      <RequestDemoDrawer open={openRequestDemo} handleClose={() => setOpenRequestDemo(false)} />
      <SignUpDrawer open={openGettingStarted} handleClose={() => setOpenGettingStarted(false)} />
      <PricingDrawer open={openPriceCalculator} handleClose={() => setOpenPriceCalculator(false)} />
    </Layout>
  )
}

export default EducationPage
